body,
html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch;
  -webkit-text-size-adjust: none;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  margin: 0px;
  padding: 0px;
  font-family: sans-serif;
}

a,
a:hover {
  text-decoration: none !important;
}

.tac {
  text-align: center;
}

.df {
  display: flex;
}

.dif {
  display: inline-flex;
}

.db {
  display: block;
}

.dib {
  display: inline-block;
  vertical-align: top;
}

.f-wrap {
  flex-wrap: wrap;
}

.jcsb {
  justify-content: space-between;
}

.jcc {
  justify-content: center;
}

.jcfe {
  justify-content: flex-end;
}

.aic {
  align-items: center;
}

.aife {
  align-items: flex-end;
}

.gap1 {
  gap: 8px;
}

.gap2 {
  gap: 16px;
}

.gap3 {
  gap: 24px;
}

.bold {
  font-weight: bold;
}

.semi-bold {
  font-weight: 500;
}

.col {
  flex-direction: column;
}

.row {
  flex-direction: row;
}

.pointer {
  cursor: pointer;
}

.red {
  border: 1px solid red;
}

.ml1half {
  margin-left: 10px;
}

.mr1half {
  margin-right: 10px;
}

.mt1 {
  margin-top: 8px;
}

.mt2 {
  margin-top: 16px;
}

.mt3 {
  margin-top: 24px;
}

.mt4 {
  margin-top: 32px;
}

.mt5 {
  margin-top: 40px;
}

.mt6 {
  margin-top: 48px;
}

.mt7 {
  margin-top: 56px;
}

.mt8 {
  margin-top: 64px;
}

.mt9 {
  margin-top: 72px;
}

.mt10 {
  margin-top: 80px;
}

.mb1 {
  margin-bottom: 8px;
}

.mb2 {
  margin-bottom: 16px;
}

.mb3 {
  margin-bottom: 24px;
}

.mb4 {
  margin-bottom: 32px;
}

.mb5 {
  margin-bottom: 40px;
}

.mb6 {
  margin-bottom: 48px;
}

.mb7 {
  margin-bottom: 56px;
}

.mb8 {
  margin-bottom: 64px;
}

.mb9 {
  margin-bottom: 72px;
}

.mb10 {
  margin-bottom: 80px;
}

.mr1 {
  margin-right: 8px;
}

.mr2 {
  margin-right: 16px;
}

.mr3 {
  margin-right: 24px;
}

.mr4 {
  margin-right: 32px;
}

.mr5 {
  margin-right: 40px;
}

.mr6 {
  margin-right: 48px;
}

.ml1 {
  margin-left: 8px;
}

.ml2 {
  margin-left: 16px;
}

.ml3 {
  margin-left: 24px;
}

.ml4 {
  margin-left: 32px;
}

.ml5 {
  margin-left: 40px;
}

.ml6 {
  margin-left: 48px;
}

.gap1 {
  gap: 8px;
}

.gap2 {
  gap: 16px;
}

.gap3 {
  gap: 24px;
}

.gap4 {
  gap: 32px;
}

.gap5 {
  gap: 40px;
}

.gap6 {
  gap: 48px;
}

.gap7 {
  gap: 56px;
}

.gap8 {
  gap: 64px;
}

.gap9 {
  gap: 72px;
}

.gap10 {
  gap: 80px;
}

.p1 {
  padding: 8px;
}

.p2 {
  padding: 16px;
}

.p3 {
  padding: 24px;
}

.p4 {
  padding: 32px;
}

.p5 {
  padding: 40px;
}

.p6 {
  padding: 48px;
}

.pl1 {
  padding-left: 8px;
}

.pl2 {
  padding-left: 16px;
}

.pl3 {
  padding-left: 24px;
}

.pl4 {
  padding-left: 32px;
}

.pl5 {
  padding-left: 40px;
}

.pl6 {
  padding-left: 48px;
}

.pr1 {
  padding-right: 8px;
}

.pr2 {
  padding-right: 16px;
}

.pr3 {
  padding-right: 24px;
}

.pr4 {
  padding-right: 32px;
}

.pr5 {
  padding-right: 40px;
}

.pr6 {
  padding-right: 48px;
}

.px1 {
  padding-left: 8px;
  padding-right: 8px;
}

.px2 {
  padding-left: 16px;
  padding-right: 16px;
}

.px3 {
  padding-left: 24px;
  padding-right: 24px;
}

.px4 {
  padding-left: 32px;
  padding-right: 32px;
}

.px5 {
  padding-left: 40px;
  padding-right: 40px;
}

.px6 {
  padding-left: 48px;
  padding-right: 48px;
}

.py1 {
  padding-top: 8px;
  padding-bottom: 8px;
}

.py2 {
  padding-top: 16px;
  padding-bottom: 16px;
}

.py3 {
  padding-top: 24px;
  padding-bottom: 24px;
}

.py4 {
  padding-top: 32px;
  padding-bottom: 32px;
}

.py5 {
  padding-top: 40px;
  padding-bottom: 40px;
}

.py6 {
  padding-top: 48px;
  padding-bottom: 48px;
}

.mat {
  margin-left: auto;
  margin-right: auto;
}

.underline {
  text-decoration: underline;
}

.allcaps {
  text-transform: uppercase;
}

.cap {
  text-transform: capitalize;
}

.radius {
  border-radius: 4px;
}

.circle {
  border-radius: 50%;
}

.bg-white {
  background-color: #fff;
}

.shadow {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.relative {
  position: relative;
}

.index-150 {
  z-index: 150;
}

.tablet-only,
.landscape-only {
  display: none;
}

.container {
  padding: 0 20px;
  max-width: 1440px;
  position: relative;
  width: 100%;
}

.clear:after {
  content: "";
  display: table;
  clear: both;
}

.green {
  color: #7cc99e;
}

.gray {
  color: #757575;
}
.dark-gray {
  color: #444;
}

.invisible {
  opacity: 0;
}

.test {
  border: 1px solid red;
}

.red-hat {
  font-family: "Red Hat Text", sans-serif;
}

.fs10 {
  font-size: 10px;
}

.fs11 {
  font-size: 11px;
}

.fs12 {
  font-size: 12px;
}

.fs13 {
  font-size: 13px;
}

.fs14 {
  font-size: 14px;
}

.fs15 {
  font-size: 15px;
}

.fs16 {
  font-size: 16px;
}

.fs18 {
  font-size: 18px;
}

.fs20 {
  font-size: 20px;
}

.italic {
  font-style: italic;
}

.MuiAutocomplete-noOptions.MuiAutocomplete-noOptions {
  padding: 0px;
}

.full-width {
  width: 100%;
}
/* *::-webkit-scrollbar {
  width: 4px;
  background-color: green;
} */

/* *::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px red;
}

*::-webkit-scrollbar-thumb {
  background-color: blue;
  outline: 1px solid slategrey;
  border-radius: 4px;
} */

.Mui-selected.Mui-selected {
  font-weight: bold;
}
