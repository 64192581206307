html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
input,
button,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  box-sizing: border-box;
  vertical-align: baseline;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

html {
  scroll-behavior: smooth;
}

body {
  -moz-osx-font-smoothing: grayscale;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  -webkit-font-smoothing: antialiased;
  -webkit-overflow-scrolling: touch;
  -webkit-text-size-adjust: none;
  box-sizing: border-box;
  line-height: 1;
  font-family: Nunito Sans, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans,
    Droid Sans, Helvetica Neue, sans-serif;
}

ol,
ul {
  list-style: none;
}

img {
  display: block;
  width: 100%;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: inherit;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input:focus {
  outline: none;
}

.relative {
  position: relative;
}

.banner {
  background: #1f2b5e24;
  position: relative;
}

.banner__content {
  align-items: center;
  display: flex;
  margin: 0 auto;
  max-width: 1050px;
  padding: 8px;
}

.banner__text {
  flex-grow: 1;
  line-height: 1.4;
  font-family: "Quicksand", sans-serif;
}

.banner__close {
  background: none;
  border: none;
  cursor: pointer;
}

.banner__text,
.banner__close > span {
  color: #000e48;
}

.beta-button {
  background-color: #1f2b5e;
  border-radius: 4px;
  border: none;
  box-shadow: none;
  color: #fff;
  margin-right: 16px;
  opacity: 1;
  padding: 10px;
  width: 290px;
}

.beta-button:hover,
.banner__close:hover {
  opacity: 0.8;
}

.hideBanner {
  display: none;
}

.small_beta-button {
  background-color: #00d8a6;
  cursor: pointer;
  padding: 0 16px;
  height: 38px;
  z-index: 1;
  font-weight: 600;
  width: auto;
  display: none;
}

body .beamer_defaultBeamerSelector {
  display: none;
}

@media (max-width: 767px) {
  .mobile-hide {
    display: none;
  }
}

@media (min-width: 1024px) {
  .small_beta-button {
    display: flex;
    align-items: center;
  }

  body .beamer_defaultBeamerSelector {
    display: block;
  }
}
